<template>
  <div class="height1">
    <bread></bread>
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="状态">
                <el-select v-model="searchForm.state" placeholder="请选择" size="small" style="margin-right: 10px" @change="Search">
                  <el-option v-for="item in statusOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="tableData" stripe style="width: 100%" :header-cell-style="{ background: '#F5F7FC' }" height="calc(100% - 80px)">
          <el-table-column type="expand">
            <template #default="scope">
              <el-row type="flex" class="row-bg" justify="start">
                <el-col :span="4">
                  <div class="info-item" v-if="scope.row.idCardFront">
                    <span style="font-size: 12px">身份证正面：</span>
                    <el-image hide-on-click-modal :src="`${path}${scope.row.idCardFront}`" preview-teleported :zoom-rate="1.2" :preview-src-list="[`${path}${scope.row.idCardFront}`]" fit="cover"></el-image>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="info-item" v-if="scope.row.idCardBack">
                    <span style="font-size: 12px">身份证反面：</span>
                    <el-image hide-on-click-modal :src="`${path}${scope.row.idCardBack}`" preview-teleported :zoom-rate="1.2" :preview-src-list="[`${path}${scope.row.idCardBack}`]" fit="cover"></el-image>
                  </div>
                </el-col>
                <el-col :span="16">
                  <div class="info-item" v-if="scope.row.certificateImage">
                    <span style="font-size: 12px">资质证书：</span>
                    <template v-if="scope.row.certificateImage.split(',').length>0">
                      <el-image hide-on-click-modal :src="`${path}${item}`" preview-teleported :zoom-rate="1.2" :preview-src-list="[`${path}${item}`]" fit="cover" v-for="item in scope.row.certificateImage.split(',')"></el-image>
                    </template>
                  </div>
                </el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column type="index" label="编号"> </el-table-column>
          <el-table-column prop="userName" label="账号" width="110" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.userName }}</p>
              <p>{{ scope.row.phone }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="审核状态" align="center">
            <template slot-scope="scope">
              <el-tag type="success"  v-if="scope.row.state==1">同意</el-tag>
              <el-tag type="danger" v-if="scope.row.state==2">拒绝【{{scope.row.remark}}】</el-tag>
              <el-tag type="warning" v-if="scope.row.state==0">待审核</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="申请时间" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="auditTime" label="审核时间" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.auditTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="AgreeBtn(scope.row)" v-if="scope.row.state==0">同意</span>
              <span class="danger optionBtn" @click="RefuseBtn(scope.row)" v-if="scope.row.state==0">拒绝</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog title="拒绝理由" :visible.sync="refuseDialog" width="450px">
      <el-form class="dialog-form" :model="refuseForm" ref="refuseForm" :rules="rules" :label-width="formLabelWidth">
        <el-form-item prop="remark">
          <el-input v-model="refuseForm.remark" type="textarea" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('refuseForm')">取 消</el-button>
        <el-button type="primary" @click="SaveBtn('refuseForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { User } from '../../../components/HospitalDomain/User'
export default {
  data() {
    var user = new User(this.TokenClient, this.Services.Authorization)
    return {
      userDomain: user,
      headers: { 'Content-Type': 'multipart/form-data;charset=UTF-8' },
      tableData: [],
      pageIndex: 1,
      path:this.Services.Authorization,
      dataTotal: 1,
      pageSize: 15,
      setDialog: false,
      DialogTitle: '添加银行卡',
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        },
      },
      searchForm: {
        state: '-1',
      },
      statusOption: [
        {
          value: '-1',
          label: '全部',
        },
        {
          value: '0',
          label: '待审核',
        },
        {
          value: '1',
          label: '审核通过',
        },
        {
          value: '2',
          label: '审核未通过',
        },
      ],
      rules: {
        remark: [{ required: true, message: "请填写拒绝理由", trigger: "blur" }],
      },
      title: '余额明细',
      DataCount: 0,
      json_fields: {
        订单编号: {
          filed: 'key',
          callback: (val) => {
            return 'NO：' + val.key
          },
        },
        当前余额: 'name',
      },
      fileList: [],
      formLabelWidth: '15px',
      refuseDialog: false,
      refuseForm: {
        is: 0,
        state: false,
        remark: '',
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    AgreeBtn(item) {
      var _this = this
      _this
        .$confirm('审核通过，是否继续您的操作?', '提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'warning',
        })
        .then(() => {
          let map = {
            id: item.id,
            state: 1,
            remark: '',
          }
          _this.userDomain.SubmitUserCertificateAudit(
            map,
            function (data) {
              _this.$message({
                type: 'success',
                message: '已通过',
              })
              _this.getList()
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        })
        .catch(() => {
          _this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    RefuseBtn(item) {
      var _this = this
      _this.refuseDialog = true
      _this.refuseForm = {
        id: item.id,
        state: 2, //申请状态 -1 全部 0待审核 1 审核通过 2 审核未通过
        remark: '',
      }
    },
    SaveBtn(formName) {
      var _this = this
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.userDomain.SubmitUserCertificateAudit(
            this.refuseForm,
            function (data) {
              _this.$message({
                type: 'success',
                message: '已拒绝!',
              })
              _this.$refs[formName].resetFields()
              _this.refuseDialog = false
              _this.getList()
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    CloseBtn(formName) {
      this.$refs[formName].resetFields()
      this.refuseDialog = false
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex
      this.getList()
    },
    getList() {
      var _this = this
      _this.tableData = []
      _this.userDomain.GetCertificateListPage(
        _this.searchForm.state,
        this.pageIndex,
        this.pageSize,
        function (data) {
          _this.tableData = data.data.results
          _this.pageIndex = data.data.pageIndex
          _this.pageSize = data.data.pageSize
          _this.dataTotal = data.data.dataTotal
        },
        function (err) {
          console.log(err)
        }
      )
    },
    Search(event) {
      this.pageIndex = 1
      this.getList()
    },
  },
}
</script>

<style scoped>
.dialog-form {
  width: 85%;
}
.info-item .el-image {
  width: 80px;
  height: 50px;
  vertical-align: middle;
  margin-right: 10px;
}
</style>
